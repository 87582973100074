import React from 'react';
import styles from './Header.module.css';

const Header = () => {
    return (
        <div style={{marginBottom: '2rem'}}>
            <div className={styles.container}>
                <img src="/logo.png" alt="logo" className={styles.nsw_logo}/>
                <img src="/visitme-logo-blue.png" alt="logo" className={styles.logo}/>
            </div>
        </div>
    );
};

export default Header;