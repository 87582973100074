import styles from './App.js.module.css';
import {
    Button,
    Container,
    P, H4
} from './components';
import Header from './Header';

function App() {

    const staffClicked = async () => {
        window.location.href = process.env.REACT_APP_NURSE_APP_URL;
    };

    const patientClicked = async () => {
        window.location.href = process.env.REACT_APP_PATIENT_APP_URL;
    };

    const buttonOverrideCSS = { 
        borderRadius: '1rem',
        marginBottom: '1rem'
    };
    const pOverrideCSS = { 
        justifySelf: 'center', 
        lineHeight: '4rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    };
    return (
        <Container>
            <Header />
            <div className={styles.loginContainer}>
                <div className={styles.loginGrid}>
                    <H4 className={styles.mrgn}>Welcome to VisitMe</H4>
                    <H4 className={styles.mrgnSml} style={{fontSize: '1.1rem'}}>Which of the following are you trying to do?</H4>
                    <P className={styles.mrgn}>Click on one of the two buttons below to be taken to the correct web page.</P>
                    <div style={{display: 'flex', width: '80vw'}}>
                        <div className={styles.description}>
                            <Button style={{backgroundColor: '#2E5299', ...buttonOverrideCSS}} onClick={staffClicked}>Access the VisitMe Staff Dashboard</Button>
                            <P>On the VisitMe Dashboard, you'll be able to perform different tasks, such as:</P>
                            <ul>
                                <li><P>create a new patient file</P></li>
                                <li><P>edit and manage existing patient files</P></li>
                                <li><P>see an overview of all VisitMe patients</P></li>
                                <li><P>see if a patient has an assigned device</P></li>
                                <li><P>see whether a patient is in a call</P></li>
                            </ul>
                        </div>
                        <P style={pOverrideCSS}>or</P>
                        <div className={styles.description}>
                            <Button style={{backgroundColor: '#0085B3', ...buttonOverrideCSS}} onClick={patientClicked}>Set up a device for a VisitMe patient</Button>
                            <P>Select this option <span style={{textDecoration: 'underline'}}>if you are on the device</span> that you would like to assign to a patient</P>
                            <P>You'll be asked to create a new patient file or choose an existing patient file</P>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default App;
